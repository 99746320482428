"use client";
import { getHomepagePath } from "@/app/_services/auth.action";
import { UserType } from "@/services/constants/global";
import { useUserInfo } from "@/services/hooks/useUserInfo";
import { Button, Link } from "@chakra-ui/react";
import { get } from "lodash";
import { FaChevronRight } from "react-icons/fa6";
import { useIsClient } from "usehooks-ts";

export default function HomeButton() {
  const user = useUserInfo();
  const isClient = useIsClient();

  if (!isClient) {
    return null;
  }

  return (
    <Link href={getHomepagePath(get(user, "type", UserType.PERSONAL))}>
      <Button px={{ base: 2, md: 4 }} rightIcon={<FaChevronRight />}>
        모두보기
      </Button>
    </Link>
  );
}
