"use client";
import { Image, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export default function DefaultBannerImage() {
  const { t } = useTranslation();
  return (
    <Stack
      w="full"
      h="full"
      gap={2.5}
      alignItems="center"
      justifyContent="center"
      bgColor="#F6F7FA"
    >
      <Image h="50%" src="/images/banner_default.svg" alt="ads" />
      <Text color="placeholder">{t("Advertisement")}</Text>
    </Stack>
  );
}
