import { Img, Stack, Text } from "@chakra-ui/react";

export default function JobCardPlaceholder(props) {
  return (
    <Stack
      minH={240}
      opacity={0.2}
      align="center"
      justify="center"
      gap={0}
      h="full"
      w="full"
      {...props}
    >
      <Img src="/beauti_bucks_logo.jpeg" h="60px" alt="logo" />
      <Text fontSize="sm">Coming soon!</Text>
    </Stack>
  );
}
