import { StorageKey } from "@/services/constants/global";
import {
  useLocalStorage,
  useReadLocalStorage,
  useSessionStorage,
} from "usehooks-ts";

export const useUserInfo = () => {
  const localStoreData = useReadLocalStorage(StorageKey.userData);
  const [sessionStoreData] = useSessionStorage(StorageKey.userData);
  return localStoreData || sessionStoreData;
};

export const useSetUserInfo = () => {
  const [value, setLocalFunc] = useLocalStorage(StorageKey.userData, null);
  const [, setSessionFunc] = useSessionStorage(StorageKey.userData);
  return value ? setLocalFunc : setSessionFunc;
};
