import axios from "axios";
import { differenceInYears } from "date-fns";
import { assign, attempt, compact, difference, keys, pick } from "lodash";

export const ignoreCaseIncludes = (source = "", search = "") => {
  const lowercaseSource = source.toString().trim().toLowerCase();
  const lowercaseKeyword = search.toString().trim().toLowerCase();
  return lowercaseSource.includes(lowercaseKeyword);
};

export const assignExistedKeys = (options, defaults) =>
  assign({}, options, pick(defaults, keys(options)));

export const objectRemoveKeys = (object = {}, filterKeys = []) => {
  const resultKeys = difference(keys(object), filterKeys);
  const result = pick(object, resultKeys);
  return result;
};

export const getYoutubeId = (url) => {
  if (!url) return "";
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url?.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
};

export const getFileType = (filename) => {
  if (!filename) return;
  return filename.split(".").pop();
};

export const calculateAge = (dob) => {
  if (!dob) return "";
  const age = differenceInYears(new Date(), new Date(dob));
  return age.toString();
};

export const getDownloadUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  const objectUrl = URL.createObjectURL(blob);
  return objectUrl;
};

export const onCopyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

export const getCurrentEnv = () => {
  const currentApiUrl = process.env.NEXT_PUBLIC_API_URL;
  if (currentApiUrl.includes("staging")) return "staging.";
  if (currentApiUrl.includes("test")) return "test.";
  return "";
};

export const revalidateCachePath = (path) => {
  axios.get(
    `https://${getCurrentEnv()}beautibucks.com/api/revalidate?path=${path}`
  );
};

export const splitStringData = (string) =>
  compact(string?.split(/[(\r?\n);]/) ?? []);

export const tryJSONparse = (string) => attempt(JSON.parse.bind(null, string));
