import { toast } from "@/app/providers";
import {
  ApiBaseUrl,
  StorageKey,
  defaultLanguage,
} from "@/services/constants/global";
import { HOMEPAGE_PATH } from "@/services/constants/routerPath";
import { objectTrimmer } from "@/services/utils/formatter";
import axios from "axios";

import en from "@/services/translations/locales/en.json";
import ko from "@/services/translations/locales/ko.json";
import { tryJSONparse } from "@/services/utils/general";

const REQ_TIMEOUT = 25 * 1000;
const RESPONSE_ERROR_CODE = {
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  SERVER_ERROR: 500,
};

const ApiInstance = axios.create({
  baseURL: ApiBaseUrl,
  timeout: REQ_TIMEOUT,
});

const getAccessToken = () => {
  const accessToken =
    localStorage.getItem(StorageKey.xToken) ||
    sessionStorage.getItem(StorageKey.xToken);

  return JSON.parse(accessToken);
};
const refreshToken = () => {
  if (getAccessToken()) {
    window.location.href = HOMEPAGE_PATH;
    localStorage.clear();
  }
  //handle refresh token
};
const requestHandler = (config) => {
  const authToken = getAccessToken();
  if (authToken) config.headers.Authorization = `Bearer ${authToken}`;
  if (config.data) config.data = objectTrimmer(config.data);

  return config;
};
const errorHandler = (error) => {
  const errorCode = error.response?.status;

  if (errorCode === RESPONSE_ERROR_CODE.UNAUTHORIZED) {
    const config = error.config;
    config._isRefreshToken = true;
    refreshToken(config);
  } else {
    const currentLanguage =
      tryJSONparse(localStorage.getItem(StorageKey.language)) ||
      defaultLanguage;
    const lang = currentLanguage === "ko" ? ko : en;
    const errorMessage =
      error.response?.data?.locale?.[currentLanguage] ||
      error.response?.data?.description ||
      (currentLanguage === "ko"
        ? "문제가 발생했습니다"
        : "Something went wrong");

    toast({
      title: lang["Error"],
      description: errorMessage,
      status: "error",
      duration: 3000,
      isClosable: true,
    });
  }
  return Promise.reject(error.response.data);
};

ApiInstance.interceptors.request.use((config) => requestHandler(config));

ApiInstance.interceptors.response.use(
  (response) => response.data.data,
  errorHandler
);

export default ApiInstance;
